import React from "react"
import { DatePicker as AntdDatePicker, DatePickerProps, Button } from "antd"
import moment from "moment"
import { useTranslation } from "react-i18next"
import locale from "antd/lib/date-picker/locale/ja_JP"
import "moment/locale/ja"
import {
  checkForWidth,
  handleScrollBodyBehavior,
  handleScrollTableBehavior,
} from "../../../utils"
import { DatePickerCompProps } from "../../../interface"

const dateFormat = "YYYY年MM月DD日"

const SharedDatePicker: React.FC<DatePickerCompProps & DatePickerProps> = (
  props
) => {
  const { t } = useTranslation()
  const {
    date,
    onDateChange,
    placeholder,
    style,
    name,
    disabledDate,
    disabled,
    picker = "date",
    format = dateFormat,
    showArrow = false,
    onBlur,
    clear = true,
    icon,
    scrollPreventEl,
  } = props
  moment.updateLocale("ja", {
    weekdaysMin: ["日", "月", "火", "水", "木", "金", "土"],
    monthsShort: [
      "01月",
      "02月",
      "03月",
      "04月",
      "05月",
      "06月",
      "07月",
      "08月",
      "09月",
      "10月",
      "11月",
      "12月",
    ],
  })
  const arrows = showArrow
    ? {}
    : {
        superPrevIcon: null,
        superNextIcon: null,
      }
  const [openCalendar, setOpenCalendar] = React.useState(false)
  const [mode, setMode] = React.useState<any>(picker)
  const [dateMode, setDateMode] = React.useState<any>("")

  React.useEffect(() => {
    handleScrollBodyBehavior(openCalendar)
    if (scrollPreventEl) {
      handleScrollTableBehavior(openCalendar, scrollPreventEl)
    }
  }, [openCalendar])

  React.useEffect(() => {
    setMode(mode)
  }, [dateMode])

  const [defaultFormat, setDefaultFormat] = React.useState<any>(format)

  const renderFooter = () => {
    return (
      <div className={"faculty-datepicker-custom-footer"}>
        <div className={"faculty-datepicker-custom-footer-first"}>
          <Button
            onClick={() => {
              onDateChange(moment())
              setMode(picker === "month" ? "month" : "date")
            }}
            shape={"round"}
            style={{
              padding: "2px 8px",
              width: "fit-content",
            }}
          >
            {t("Today")}
          </Button>
          {clear && (
            <Button
              onClick={() => {
                if (
                  dateMode === "decade" ||
                  dateMode === "year" ||
                  (dateMode === "month" && picker !== "month")
                ) {
                  setMode(
                    picker === "month"
                      ? mode === "month"
                        ? "mm"
                        : "month"
                      : mode === "date"
                      ? "default"
                      : "date"
                  )
                } else {
                  if (!moment(date).isValid()) {
                    onDateChange(moment(date))
                  } else {
                    onDateChange(null)
                  }
                }
              }}
              shape={"round"}
              style={{
                padding: "2px 8px",
                width: "fit-content",
              }}
            >
              {dateMode === "decade" ||
              dateMode === "year" ||
              (dateMode === "month" && picker !== "month")
                ? t("Back")
                : t("Clear")}
            </Button>
          )}
        </div>
        <div className={"faculty-datepicker-custom-footer-second"}>
          <Button
            onClick={() => {
              if (picker === "month") {
                setMode("month")
              } else {
                setMode("date")
              }
              setOpenCalendar(false)
            }}
            shape={"round"}
            style={{
              padding: "2px 8px",
              width: "fit-content",
            }}
          >
            {t("Close")}
          </Button>
        </div>
      </div>
    )
  }

  return (
    <>
      <div
        className={
          openCalendar
            ? "faculty-datepicker faculty-datepicker-mobile-open"
            : "faculty-datepicker"
        }
      >
        <AntdDatePicker
          locale={locale}
          suffixIcon={React.createElement(icon)}
          allowClear={false}
          picker={mode === "mm" ? "month" : mode}
          className={props.className}
          inputReadOnly={checkForWidth(openCalendar)}
          placeholder={`${format || placeholder}`}
          value={moment(date)?.isValid() ? date : null}
          showToday={false}
          format={defaultFormat}
          style={{
            minWidth: picker === "month" ? "120px" : "150px",
            ...style,
          }}
          name={name}
          disabled={disabled}
          open={openCalendar}
          disabledDate={disabledDate}
          status={props.status}
          {...arrows}
          onOpenChange={(val) => {
            setOpenCalendar(val)
          }}
          onChange={(value) => {
            onDateChange(value)
          }}
          onPanelChange={(_, currentMode) => {
            setDateMode(currentMode)
          }}
          onKeyDown={() => {
            setDefaultFormat(picker === "month" ? "YYYYMM" : "YYYYMMDD")
          }}
          onBlur={
            onBlur
              ? onBlur
              : () => {
                  if (picker === "month") {
                    setOpenCalendar(false)
                    setMode("month")
                    setMode(mode === "month" ? "month" : "month")
                    setDateMode("")
                  } else {
                    setOpenCalendar(false)
                    setMode(mode === "date" ? "date" : "date")
                    setDateMode("")
                  }
                  setDefaultFormat(format)
                }
          }
          renderExtraFooter={() => renderFooter()}
          getPopupContainer={props.getPopupContainer}
        />
        <style>{`

      .faculty-datepicker .ant-picker {
        border-radius: 5px;

      }
      .ant-picker-panel-container {
        box-shadow: none;
        border-radius: 12px;
        border: 1px solid #D9DEE0;
      }
      .ant-picker-cell {
        border: 1px solid #D9DEE0;
      }
      .ant-picker-cell:not(.ant-picker-cell-in-view) {
        background: #F1F4F5;
      }
      .ant-picker-cell:not(.ant-picker-cell-in-view) .ant-picker-cell-inner {
        visibility: hidden;
      }
      .ant-picker-cell-selected {
        background: #ECDCDD;
      }
      .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
        background: transparent;
        color: #000000;
      }
      .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
        border: none;
      }
      .ant-picker-header {
        position: relative;
        padding: 16px 8px;
      }
      .ant-picker-header-prev-btn {
        position: absolute;
        left: 10px;
      }
      .ant-picker-header-next-btn {
        position: absolute;
        right: 10px;
      }
      .ant-picker-header, .ant-picker-footer-extra:not(:last-child) {
        border-bottom: none;
      }
      .ant-picker-footer {
        border-top: none !important;
      }
      .faculty-datepicker-custom-footer {
        display: flex;
        justify-content: space-between;
        padding: 16px 0;
        align-items: center;
      }
      .faculty-datepicker-custom-footer-first {
        width: 50%;
        display: flex;
        justify-content: space-between;
        gap:
      }
      .ant-picker-year-btn, .ant-picker-month-btn {
        position: relative;
        border: 1px solid #D2D1D1 !important;
        border-radius: 5px;
        padding: 4px 10px 4px 6px !important;
        line-height: 1.5 !important;
        width: 80px;
      }
      .ant-picker-month-btn {
        min-width: 75px;
      }
      .ant-picker-year-btn:after, .ant-picker-month-btn:after {
        content: '${"\\2304"}';
        position: absolute;
        right: 4px;
        top: 0px;
      }
      .field-error {
        font-size: 12px;
        color: #eb5757;
        margin-top: 2px;
        max-width: 150px;
      }
      .ant-picker-cell-today:not(.ant-picker-cell-selected) {
        background: #fbf5ee;
      }
     .ant-picker > .ant-picker-input >input::placeholder{
        font-size:12px
     }
      @media (max-width: 768px) {
        .faculty-datepicker-mobile-open:after {
            content: "";
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            background: rgba(0,0,0,0.6);
            z-index: 99;
        }
        .ant-picker-dropdown {
            position: fixed;
            top: calc(50vh - 220px) !important;
            left: calc(50vw - 140px) !important;
        }
      }
      `}</style>
        {props.error && <div className={"field-error"}>{props.error}</div>}
      </div>
    </>
  )
}

export { SharedDatePicker }
